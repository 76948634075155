import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import {TransactionService} from "../../../../services/transaction.service";
import {UserContext} from "../../../../context/UserContext";
import {ITransactionObject} from "../../../../services/responses/objects/ITransactionObject";

const TransactionTableComponent: React.FC = ()  => {

    const {user} = useContext(UserContext);
    const [tableLoading, setTableLoading] = useState<boolean>(true);

    const [page, setPage] = useState<number>(1);
    const [offset, setOffset] = useState<number>(1);

    const [maxPages, setMaxPage] = useState<number>(Number.MAX_VALUE);
    const [transactions, setTransactions] = useState<ITransactionObject[]>([]);

    const [lastTransactionId, setLastTransactionId] = useState<string | undefined>(undefined);

    const customId = "custom-simly-toast";

    const toastOptions = {
        position: toast.POSITION.TOP_CENTER,
        toastId: customId,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false
    }

    const fetchSummary = async () => {
        setTableLoading(true)

        const data = await TransactionService.getPageTransactions(page, lastTransactionId);

        if(data.data.length == 0) {
            setTransactions([]);
        } else {
            setTransactions(data.data);
            setLastTransactionId(data.data[data.data.length - 1].id);
        }

        setTableLoading(false);
    };

    useEffect(() => {
        fetchSummary().catch(() => toast.error('Unable to fetch the data from source.', toastOptions));
    }, [page])

    const range = function(start: number, end: number, step: number) {
        const result = []

        for (let i = start; i <= end; i += step) {
            result.push(i);
        }

        return result;
    }

    return (
        <div className={"w-full h-full flex flex-col sm:items-center sm:justify-center sm:mt-5 overflow-x-auto"}>
                <div className="w-[44rem] sm:w-11/12 border border-gray-200 flex flex-col items-center justify-center rounded-lg mt-14 mb-7">
                    <div className={"w-full h-8 flex flex-row items-center border-b border-gray-200 px-3"}>
                        <p className={"text-xs lg:text-sm text-gray-500 font-semibold w-[17%] lg:w-[15%]"}>Transaction date</p>
                        <p className={"text-xs lg:text-sm text-gray-500 font-semibold w-[20%]"}>ICCID</p>
                        <p className={"text-xs lg:text-sm text-gray-500 font-semibold w-[15%]"}>Package</p>
                        <p className={"text-xs lg:text-sm text-gray-500 font-semibold w-[15%]"}>Email</p>
                        <p className={"text-xs lg:text-sm text-gray-500 font-semibold w-[7.5%] lg:w-[10%]"}>Price</p>
                        <p className={"text-xs lg:text-sm text-gray-500 font-semibold w-[7.5%] lg:w-[10%]"}>Received</p>
                        <p className={"text-xs lg:text-sm text-gray-500 font-semibold"}>Type</p>
                    </div>

                    {tableLoading ? (<>
                        {[...Array(5)].map(i =>
                            <div key={i} className={"w-full h-16 flex flex-row items-center border-b border-gray-200 px-3"}>
                                <div className={"text-xs lg:text-sm text-black font-semibold w-[17%] lg:w-[15%]"}>
                                    <div className={"w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"}></div>
                                </div>
                                <div className={"w-[20%] lg:w-[20%] flex flex-row items-center justify-start"}>
                                    <div className={"w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"}></div>
                                </div>
                                <div className={"w-[20%] lg:w-[20%] flex flex-row items-center justify-start"}>
                                    <div className={"w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"}></div>
                                </div>
                                <div className={"text-xs lg:text-sm text-black font-semibold w-[20%] truncate"}>
                                    <div className={"w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"}></div>
                                </div>
                                <div className={"text-xs lg:text-sm text-black font-semibold w-[8%] lg:w-[12.25%]"}>
                                    <div className={"w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"}></div>
                                </div>
                                <div className={"text-xs lg:text-sm text-black font-semibold w-[10%] lg:w-[15%]"}>
                                    <div className={"w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"}></div>
                                </div>
                                <div className={"flex flex-row items-center justify-center animate-pulse bg-gray-200 rounded h-4 sm:h-8 w-[12%]"}>
                                </div>

                            </div>)}
                    </>) : (<>
                        {transactions.map(entry =>
                            <div key={entry.iccid} className={"w-full h-16 flex flex-row items-center border-b border-gray-200 px-3"}>
                                <p className={"text-xs lg:text-sm text-black font-semibold w-[17%] lg:w-[15%]"}>{
                                    new Date(entry.date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })
                                }</p>
                                <div className={"w-[20%] flex flex-row items-center justify-start"}>
                                    <p className={"text-black text-xs lg:text-sm font-semibold"}>{entry.iccid}</p>
                                </div>
                                <div className={"w-[15%] flex flex-row items-center justify-start"}>
                                    <img alt={entry.isoCode} src={entry.countryImageURL} className={"rounded-full w-4 h-4 lg:w-5 lg:h-5"}/>
                                    <p className={"pl-3 text-black text-xs lg:text-sm font-semibold"}>{entry.package} - 1GB7Days</p>
                                </div>
                                <p className={"text-xs lg:text-sm text-black font-semibold w-[15%] truncate"}>{entry.email}</p>
                                <p className={"text-xs lg:text-sm text-black font-semibold w-[7.5%] lg:w-[10%]"}>-</p>
                                <p className={"text-xs lg:text-sm text-black font-semibold w-[7.5%] lg:w-[10%]"}>-</p>

                                <div className={"flex flex-row items-center justify-center"}>
                                    {entry.active ?
                                        <div className={"w-20 h-6 lg:w-24 lg:h-9 bg-green-100 flex flex-row justify-center items-center rounded-lg"}>
                                            <p className={"text-green-600 font-semibold text-xs lg:text-sm"}>Installed</p>
                                        </div> :
                                        <div className={"w-20 h-6 lg:w-24 lg:h-9 bg-orange-100 flex flex-row justify-center items-center rounded-lg"}>
                                            <p className={"text-orange-400 font-semibold text-xs lg:text-sm"}>Pending</p>
                                        </div>}
                                </div>

                            </div>
                        )}
                    </>)}
                </div>

                <div className={"w-11/12 h-16 flex flex-row-reverse"}>
                    {maxPages === 0 ? <div className={"w-1/4 sm:w-2/6 lg:w-1/6 sm:h-8 h-full animate-pulse bg-gray-200 rounded"}></div> :
                        <div className={"flex flex-row-reverse sm:w-2/6 lg:w-1/6 h-8 justify-between items-center"}>
                            <div className={"w-7 h-8 lg:w-8 lg:h-9 flex flex-row items-center justify-center bg-white cursor-pointer"}>
                                <FontAwesomeIcon className={`${offset + 5 > maxPages ? 'text-gray-200 font-bold' : 'text-gray-500 opacity-50'}`} icon={faChevronRight}
                                                 onClick={() => {
                                                     if(offset + 5 <= maxPages)
                                                         setOffset(offset + 1)
                                                 }}/>
                            </div>

                            {range(offset, offset + 4, 1).reverse().map(val =>
                                <div key={val} className={`w-7 h-8 lg:w-8 lg:h-9 flex flex-row items-center justify-center ${val === page ? 'bg-gray-100 rounded-lg' : 'bg-white'} cursor-pointer`} onClick={
                                    () => {
                                        if(val !== page)
                                            setPage(val)
                                    }
                                }>
                                    <p className={`text-gray-900 font-bold ${val !== page ? 'opacity-50' : ''}`}>{val}</p>
                                </div>
                            )}

                            <div className={"w-7 h-8 lg:w-8 lg:h-9 flex flex-row items-center justify-center bg-white cursor-pointer"}>
                                <FontAwesomeIcon className={`${offset === 1 ? 'text-gray-200 font-bold' : 'text-gray-500 opacity-50'}`} icon={faChevronLeft}
                                                 onClick={() => {
                                                     if(offset > 1)
                                                         setOffset(offset - 1)
                                                 }}/>
                            </div>
                        </div>
                    }

                </div>
            </div>
    )
}

export default TransactionTableComponent;
