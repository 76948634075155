import {UserDTO} from "../dto/UserDTO";

export const saveUserLocally = (user: UserDTO | null) => {
    localStorage.setItem("simlyuser", JSON.stringify(user))
}

export const loadUserLocally = () : UserDTO | null => {
    const state = localStorage.getItem("simlyuser")
    return state ? (JSON.parse(state) as UserDTO) : null;
}
