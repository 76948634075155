import {getAccessToken, SIMLY_PARTNER_API_URL} from "../utils/config";
import {IDashboardSummary} from "./responses/dashboard.responses";
import {BasicResponse} from "./responses/basic.response";
import {ITransactionObject} from "./responses/objects/ITransactionObject";

export class TransactionService {

    public static getDashboardSummary = async (): Promise<BasicResponse<IDashboardSummary>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + "/vfs/dashboard", {
            method: "GET",
            headers: {
                'x-simly-token': getAccessToken()!,
                'Content-Type': 'application/json'
            },
        });

        return await resp.json() as BasicResponse<IDashboardSummary>;
    }

    public static getPageTransactions = async (page: number, startAfter?: string): Promise<BasicResponse<ITransactionObject[]>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + `/vfs/stats?page=${page}${startAfter != undefined ? `&startAfter=${startAfter}` : ''}`, {
            method: "GET",
            headers: {
                'x-simly-token': getAccessToken()!,
                'Content-Type': 'application/json'
            }
        });

        return await resp.json() as BasicResponse<ITransactionObject[]>;
    }

}
