import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faHourglass, faHourglass1, faHourglass2, faHourglass3,
    faHourglassEnd,
    faMicrochip,
    faSheetPlastic,
    faWallet
} from "@fortawesome/free-solid-svg-icons";
import TransactionTableComponent from "./table/TransactionTableComponent";
import {TransactionService} from "../../../services/transaction.service";
import {toast} from "react-toastify";
import {UserContext} from "../../../context/UserContext";
import {IDashboardSummary} from "../../../services/responses/dashboard.responses";
import {ITransactionObject} from "../../../services/responses/objects/ITransactionObject";

const DashboardPanel: React.FC<any> = ({}) => {

    const {user, setUser} = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [summaryData, setSummaryData] = useState<IDashboardSummary>();

    const customId = "custom-simly-toast";

    const toastOptions = {
        position: toast.POSITION.TOP_CENTER,
        toastId: customId,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false
    }

    const fetchSummary = async () => {
        const data = await TransactionService.getDashboardSummary();

        setSummaryData(data.data);
        setLoading(false);
    }

    useEffect(() => {
        fetchSummary().catch(() => toast.error('Unable to fetch the data from source.', toastOptions));
    }, [])

    return (
        <div className={`w-full h-full overflow-hidden`}>
            <div className={`w-full h-full flex flex-col items-center justify-center mt-5`}>
                <div className={"w-11/12 h-16 sm:h-24 flex flex-row items-center justify-between"}>
                    <p className={"text-base font-bold sm:text-xl text-[#333333]"}>Dashboard</p>
                </div>
                <div className={"w-11/12 h-16 sm:h-24 flex flex-row items-center justify-between"}>
                    <p className={"text-[10px] font-bold"}>SUMMARY</p>
                </div>
                <div className={"w-11/12 h-64 sm:h-16 flex flex-col sm:flex-row items-center justify-between mt-5 sm:mt-0"}>
                    <div className={"w-9/12 sm:w-[32.5%] h-20 sm:h-24 flex flex-row border border-gray-200 rounded-xl items-center justify-center"}>
                        <div className={"w-10/12 xl:w-[95%] h-full flex flex-row items-center justify-start"}>
                            <div className={"w-8 h-8 xl:w-12 xl:h-12 rounded-lg flex flex-row items-center justify-center bg-gray-100"}>
                                <FontAwesomeIcon className={"text-xs xl:text-base"} icon={faHourglass2} />
                            </div>

                            <div className={"w-9/12 sm:w-11/12 h-9 xl:h-14 flex flex-col ml-4 xl:mt-2"}>
                                <p className={"text-xs text-[#828282] font-semibold"}>Pending eSIMs</p>
                                <div className={"flex flex-row w-full justify-between items-center"}>
                                    {loading ? <div className={"animate-pulse h-8 sm:h-4 w-32 bg-gray-200 rounded mt-2 sm:mt:4"}></div> : <p className={"text-base xl:text-2xl font-bold"}>{summaryData?.pending}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"w-9/12 sm:w-[32.5%] h-20 sm:h-24 flex flex-row border border-gray-200 rounded-xl items-center justify-center"}>
                        <div className={"w-10/12 xl:w-[95%] h-full flex flex-row items-center justify-start"}>
                            <div className={"w-8 h-8 xl:w-12 xl:h-12 rounded-lg flex flex-row items-center justify-center bg-gray-100"}>
                                <FontAwesomeIcon className={"text-xs xl:text-base"} icon={faCheck} />
                            </div>

                            <div className={"w-9/12 sm:w-11/12 h-9 xl:h-14 flex flex-col ml-4 xl:mt-2"}>
                                <p className={"text-xs text-[#828282] font-semibold"}>Installed eSIMs</p>
                                <div className={"flex flex-row w-full justify-between items-center"}>
                                    {loading ? <div className={"animate-pulse h-8 sm:h-4 w-32 bg-gray-200 rounded mt-2 sm:mt:4"}></div> : <p className={"text-base xl:text-2xl font-bold"}>{summaryData?.installed}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"w-9/12 sm:w-[32.5%] h-20 sm:h-24 flex flex-row border border-gray-200 rounded-xl items-center justify-center"}>
                        <div className={"w-10/12 xl:w-[95%] h-full flex flex-row items-center justify-start"}>
                            <div className={"w-8 h-8 xl:w-12 xl:h-12 rounded-lg flex flex-row items-center justify-center bg-gray-100"}>
                                <FontAwesomeIcon className={"text-xs xl:text-base"} icon={faMicrochip} />
                            </div>

                            <div className={"w-9/12 sm:w-11/12 h-9 xl:h-14 flex flex-col ml-4 xl:mt-2"}>
                                <p className={"text-xs text-[#828282] font-semibold"}>Remaining eSIMs</p>
                                <div className={"flex flex-row w-full justify-between items-center"}>
                                    {loading ? <div className={"animate-pulse h-8 sm:h-4 w-32 bg-gray-200 rounded mt-2 sm:mt:4"}></div> : <p className={"text-base xl:text-2xl font-bold"}>{summaryData?.remaining}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TransactionTableComponent/>
            </div>
        </div>
    )
}

export default DashboardPanel;
