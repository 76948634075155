import React, {useContext, useState} from "react";
import {UserContext} from "../../../context/UserContext";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {UserService} from "../../../services/user.service";
import {UserDTO} from "../../../dto/UserDTO";
import Cookies from "js-cookie";

const LoginScreen: React.FC<any> = ({}) => {

    const user = useContext(UserContext);
    const [request, setRequest] = useState<any>({
        username: '',
        password: ''
    });
    const [loading, setLoading] = useState<boolean>(false);
    const customId = "custom-simly-toast";

    const toastOptions = {
        position: toast.POSITION.TOP_CENTER,
        toastId: customId,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false
    }

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleLogin = async() => {

        if(request.username === '')
            toast.error('The username field cannot be empty.', toastOptions)
        else if(!validateEmail(request.username) || request.username != "vfs@simly.io")
            toast.error('The username field must be a valid email.', toastOptions)
        else {
            if(request.password === '')
                toast.error('The password field cannot be empty.', toastOptions)
            else {
                setLoading(true)

                const loginRequest = await UserService.login(request);

                if(loginRequest.code != 200) {
                    toast.error("Incorrect combination", toastOptions);
                } else {
                    Cookies.set('token', loginRequest.data.token, {
                        path: '/',
                        secure: true,
                        sameSite: 'None',
                    });

                    UserService.getUser().then(r => {
                        user.setUser(r.data as UserDTO);
                    })
                }

                setLoading(false)
            }
        }
    }

    return (
        <section className="w-full gradient-form bg-gray-200 h-screen flex flex-col justify-center items-center">
            <div className="flex justify-center items-center flex-wrap w-[90%] sm:w-[75%] md:w-[60%] h-full sm:h-5/6 g-6 text-gray-800">
                <div className="xl:w-10/12">
                    <div className="block bg-white shadow-lg rounded-lg">
                        <div className="flex xl:flex-wrap g-0">
                            <div className="w-full h-5/6 lg:w-9/12 xl:w-6/12 px-4 md:px-0">
                                <div className="md:p-12 md:mx-6">
                                    <div className="text-center">
                                        <img
                                            className="mx-auto w-32 sm:w-48"
                                            src="logo.svg"
                                            alt="logo"
                                        />
                                        <h4 className="text-base sm:text-xl font-semibold mt-1 mb-6 sm:mb-12 pb-1">Stay connected wherever you go</h4>
                                    </div>
                                    <form>
                                        <div className="mb-4">
                                            <input
                                                type="text"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                id="exampleFormControlInput1"
                                                placeholder="Username"
                                                value={request.username}
                                                onChange={(e) => {
                                                    setRequest({
                                                        ...request,
                                                        username: e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="password"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                id="exampleFormControlInput1"
                                                placeholder="Password"
                                                value={request.password}
                                                onChange={(e) => {
                                                    setRequest({
                                                        ...request,
                                                        password: e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="text-center pt-1 mb-6 sm:mb-12 pb-1">
                                            <button
                                                className={`flex flex-col items-center justify-center px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md transition duration-150 ease-in-out w-full mb-3
                                                    bg-gradient-to-l from-[#f68959] via-[#fcb945] to-[#fc9e40] h-10 ${loading ? 'disabled' : ''}`}
                                                type="button"
                                                onClick={handleLogin}
                                            >
                                                {loading ?
                                                    <svg className="animate-spin h-4 w-4 text-white"
                                                         xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10"
                                                                stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor"
                                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg> : <>Login</>}
                                            </button>
                                            <a className="text-gray-500" href="#">Forgot password?</a>
                                        </div>
                                        <div className="flex items-center justify-between pb-6">
                                            <p className="mb-0 mr-2 text-sm sm:text-base">Don't have an account?</p>
                                            <button
                                                type="button"
                                                className="inline-block px-6 py-2 border-2 border-red-600 text-red-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                                data-mdb-ripple="true"
                                                data-mdb-ripple-color="light"
                                            >
                                                Get help
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div
                                className="w-0 lg:w-3/12 xl:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none bg-gradient-to-r from-[#f68959] via-[#ff773b] to-[#e37f53]"
                            >
                                <div className="text-white px-4 py-6 md:p-12 md:mx-6">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LoginScreen;
