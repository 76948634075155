import React, {useContext, useEffect} from "react";
import {ActivePage} from "../../utils/ActivePage";
import NavigationPill from "./NavigationPill";
import {UserContext} from "../../context/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOut, faWallet} from "@fortawesome/free-solid-svg-icons";
import {UserService} from "../../services/user.service";

type NavigationProp = {
    activePage: ActivePage,
    setActivePage: (page: ActivePage) => void
};

const NavbarComponent: React.FC<any> = ({ activePage, setActivePage }: NavigationProp) => {

    const {user, setUser} = useContext(UserContext);

    useEffect(() => {
        if(user) {
            setUser({
                ...user,
                balance: user.balance
            })
        }
    }, [])

    return (
        <div className={"sticky top-0 flex flex-col items-center justify-center bg-white"}>
            <div className={"flex flex-col items-center justify-between w-full sm:w-11/12 h-40 mt-3 sm:flex-row sm:mt-0 sm:justify-between"}>
                <div className={"flex flex-col h-16 w-full sm:flex-row sm:h-full items-center justify-start"}>
                    <img alt="logo" src={"logo.svg"} className={"w-16 h-16 sm:w-18 sm:h-18 lg:w-20 lg:h-20"}/>

                    <div className={"flex flex-row w-full sm:w-[24rem] lg:w-[24rem] h-24 items-center justify-between sm:ml-10 lg:ml-20"}>
                        <NavigationPill pillPage={ActivePage.DASHBOARD} activePage={activePage} setActivePage={setActivePage}/>
                        <NavigationPill pillPage={ActivePage.ANALYTICS} activePage={activePage} setActivePage={setActivePage}/>
                        <NavigationPill pillPage={ActivePage.SETTINGS} activePage={activePage} setActivePage={setActivePage}/>
                    </div>
                </div>

                <button className={"w-48 h-14 sm:w-48 sm:h-14 flex flex-row items-center justify-center rounded-2xl cursor-pointer text-center bg-green-500 mb-3 sm:mb-0"} onClick={() => setUser(null)}>
                    <div className={"flex flex-row w-10/12 h-full items-center justify-between"}>

                        <div className={"flex flex-col items-start w-[80%]"}>
                            <p className={"text-xs text-white font-inter font-semibold"}>{user!.email}</p>

                        </div>

                        <FontAwesomeIcon className={"text-[1rem] text-white"} icon={faSignOut} />
                    </div>

                </button>

            </div>
            <div className={"w-screen h-[0.09rem] bg-gray-200"}></div>
        </div>
    )
}

export default NavbarComponent;
