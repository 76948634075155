import React from "react";
import {UserDTO} from "../dto/UserDTO";

interface IUserContext {
    user: UserDTO | null;
    setUser: (user: UserDTO | null) => void;
}

export const UserContext = React.createContext<IUserContext>({
    user: null,
    setUser: () => {},
});
